<template>
  <div>
    <div class="mt-n8">
      <div>
        <v-card
          color="primary radius-10 welcome-card fill-width d-flex align-center justify-center text-center overflow-hidden"
          flat
          style="min-height: 300px"
        >
          <img class="plant" src="/media/svg/dashboard/Plant.svg" alt />
          <!--  -->
          <div>
            <div
              class="
                welcome-text
                fill-height
                d-flex
                align-center
                white--text
                justify-center
                flex-column
              "
            >
              <h1 class="bold-text mb-5 mt-9">Welcome to TestApp.io Portal</h1>
              <p class="mt-4 text-center description">
                Portal will be used to upload new releases for both Android and
                iOS and inviting members to manage or install them from our
                mobile app
              </p>
            </div>
            <div>
              <v-btn
                id="letstartBtn"
                large
                color="white"
                class="
                  text-transform-none
                  primary--text
                  px-12
                  py-8
                  mt-6
                  mx-auto
                "
                :class="{ 'mb-4': $vuetify.breakpoint.smAndDown }"
                @click="$router.push({ name: 'get-started' })"
              >
                Let’s start
              </v-btn>
            </div>
          </div>

          <!--  -->
          <img
            class="plant-Right"
            src="/media/svg/dashboard/Plant-Right.svg"
            alt
          />
          <img
            class="wlcm-img"
            v-if="$vuetify.breakpoint.smAndUp"
            src="/media/svg/Icon-Character-Team.svg"
            alt
          />
        </v-card>
      </div>
      <v-row class="my-15">
        <v-col
          v-if="getTotalTeamCount && getTotalTeamCount > 0"
          cols="12"
          md="4"
        >
          <team-list from-welcome></team-list>
        </v-col>
        <v-col
          class="mt-5"
          :class="
            getTotalTeamCount && getTotalTeamCount > 0 ? '' : 'text-center'
          "
          cols="12"
          :md="getTotalTeamCount && getTotalTeamCount > 0 ? '8' : '12'"
        >
          <v-row justify="center">
            <v-col
              cols="12"
              :lg="getTotalTeamCount && getTotalTeamCount > 0 ? '12' : '8'"
            >
              <!-- <contentLoader v-if="dashboardInviteLoader || loading" /> -->
              <h4 class="bold-text font-size-h3 dark-grey-text mb-6">
                Team Invites
              </h4>
              <v-skeleton-loader
                class="transparent"
                type="table-thead, table-tbody"
                v-if="loading"
              />
              <div v-else>
                <v-data-table
                  :headers="headers"
                  :page.sync="page"
                  :server-items-length="getTotalInvitesListCount || 0"
                  :items="listOfInvites || []"
                  class="
                    v-card v-sheet v-sheet--outlined
                    hide-v-data-footer__select
                    radius-10
                    release-table
                  "
                  mobile-breakpoint="1070"
                >
                  <template slot="item" slot-scope="props">
                    <tr @click="openDrawer(props.item)">
                      <td>
                        <div class="d-flex align-center">
                          <v-avatar size="45">
                            <img src="/media/svg/default-avatar.jpg" alt />
                          </v-avatar>
                          <div class="ff-open-sans-bold fill-height ml-4">
                            {{ props.item.from_user.name }}
                          </div>
                        </div>
                      </td>
                      <td class="text-left">
                        {{ props.item.team.role }}
                      </td>
                      <td class="text-left">
                        {{ props.item.team.name }}
                      </td>
                      <td class="text-left">
                        <vue-hoverable-date
                          :date="props.item.created_at"
                        ></vue-hoverable-date>
                      </td>
                    </tr>
                  </template>
                  <template slot="no-data">
                    <div class="no-data-found">
                      <inline-svg
                        class="mt-6 svg-icon empty_icon"
                        src="/media/placeholders/no_app_members.svg"
                      />
                      <p
                        class="mt-4 empty-title bold-text text-dark text-center"
                      >
                        No invites
                      </p>
                      <p
                        class="
                          mt-4
                          mb-5
                          empty-sub-title
                          font-weight-normal
                          text-dark text-center
                        "
                      >
                        Create your own team or get invited to one to get
                        started
                      </p>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <Modal v-model="drawer1" class="invited" :loading="declineLoader">
          <template #message>
            <invite-action-modal
              v-if="drawer1"
              :current-invite-details="currentInvite"
              @close="drawer1 = false"
            />
          </template>
        </Modal>
        <vue-snack-bar></vue-snack-bar>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { get, isEmpty } from "@/core/services/helper.service";
import { GET_ALL_INVITES } from "@/store/team/invite.module";
import { GET_TEAMS } from "@/store/team/manageTeam.module.js";
import InviteActionModal from "@/view/components/Invites/ActionModal.vue";
import TeamList from "@/view/components/Team/List.vue";
// import createTeamCard from "@/view/components/cards/CreateTeamCard.vue";

export default {
  components: { TeamList, InviteActionModal },
  data() {
    return {
      currentInvite: null,
      declineLoader: false,
      acceptLoader: false,
      page: 1,
      headers: [
        { text: "Name", width: "20%", sortable: false },
        { text: "Role", align: "", width: "20%", sortable: false },
        { text: "Team", width: "15%", sortable: false },
        { text: "Sent", width: "15%", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentNotifications: "userNotificationsStats",
      layoutConfig: "layoutConfig",
      getTotalTeamCount: "getTotalTeamCount",
      getTotalInvitesListCount: "getTotalInvitesListCount",
      getTotalInviteList: "getTotalInviteList",
    }),
    ...mapState({
      user: (state) => state.auth.user,
      errMsg: (state) => get(state, "invite.inviteErrMsg", ""),
      succMsg: (state) => get(state, "invite.inviteSuccMsg", ""),
      inviteInfo: (state) => get(state, "invite.inviteInfo", ""),
      dashboardInviteLoader: (state) =>
        get(state, "invite.dashboardInviteLoader", ""),
    }),
    // here is the list of all Invites
    listOfInvites: {
      get: function () {
        let data = get(this.$store.state, "invite.listOfInvites", []);
        return data;
      },
      set: function (v) {
        this.$store.commit("setListOfInvites", v);
      },
    },
    noInvites() {
      return isEmpty(this.listOfInvites);
    },
    drawer1: {
      get() {
        return this.$store.state.dashboard.inviteDrawer1;
      },
      set(v) {
        this.$store.commit("setInviteDrawer1", v);
      },
    },
  },
  watch: {
    page(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getTotalInviteList.length > oldVal * 10) {
          this.setTotalInvitesPreviousData(
            this.getTotalInviteList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.listOfInvites[this.listOfInvites.length - 1].id;
          this.fetchInviteList(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setTotalInvitesPreviousData(
          this.getTotalInviteList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    this.setAuthMessage({ message: "", type: "" });
    this.fetchInviteList();
  },
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
      setAuthMessage: "setAuthMessage",
      setTotalInvitesPreviousData: "setTotalInvitesPreviousData",
    }),
    async fetchInviteList(lastId) {
      if (this.loading) return;
      this.loading = true;
      await this.$store
        .dispatch(GET_ALL_INVITES, { lastId, invites: true })
        .then((response) => {
          if (response && response.team) {
            this.$router.push({ name: "dashboard" }).catch(() => {});
          } else if (!lastId) {
            this.checkTeamList();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkTeamList() {
      this.$store
        .dispatch(GET_TEAMS)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openDrawer(invite) {
      this.$store.commit("setInviteDrawer1", true);
      this.currentInvite = invite;
    },
  },
};
</script>
